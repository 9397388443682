<template>
  <div class="interview-dialogue">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/interview.png`)" fit="contain"></el-image>
        <div class="head-title">AI模拟面试</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="chat-main">
      <div class="main-picture">
        <video class="deo" :autoplay="true" ref="videoElement" :srcObject="videoStream"></video>
        <el-image class="img" v-show="isDynamic" :src="require(`../../../assets/image/number.gif`)" fit="contain"></el-image>
        <el-image class="img" v-show="!isDynamic" :src="require(`../../../assets/image/numberstatic.jpg`)" fit="contain"></el-image>
      </div>
      <div class="main-content">
        <div class="content-top">
          <div class="title-button">
            <div class="title">你好，我是本次的面试官，欢迎参加本公司的线上面试！面试预计需要10分钟……</div>
            <audio-player ref="childRef" @child-event="handleChildEvent" :audioSrc="audioSrc"></audio-player>
            <el-button @click="clickReport" type="primary" size="mini" v-if="isPlay">生成报告</el-button>
            <el-button @click="startCamera" type="primary" size="mini" v-else>开始答题</el-button>
          </div>
          <div class="title-history">
            <span>{{ historyObj.topic }}</span>
          </div>
        </div>
        <div class="content-bottom">
          <el-input
            :autosize="{ minRows: 2, maxRows: 3 }"
            type="textarea"
            v-model="result"
          ></el-input>
          <div class="bottom-button">
            <div v-if="voiceStatus">
              <el-image class="recognitionlisten" :src="require(`../../../assets/image/recognitionlisten.gif`)" fit="contain"></el-image>
              <el-image class="recognitionlisten" :src="require(`../../../assets/image/recognitionlisten.gif`)" fit="contain"></el-image>
            </div>
            <div v-if="chooseMicDeviceId !== ''">
              <el-image v-if="voiceStatus" @click="startRecognition" class="recognition" :src="require(`../../../assets/image/recognition.png`)" fit="contain"></el-image>
              <el-image v-else @click="startRecognition" class="recognition" :src="require(`../../../assets/image/recognition2.png`)" fit="contain"></el-image>
            </div>
            <el-button class="stopRecognition" @click="stopRecognition" size="medium" type="primary" :disabled="disabled">发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '../../../components/AudioPlayer.vue'
import { getInterviewGPT, createInterviewGPT, getSubtitle } from '../../../request/api'
import RecordRTC from 'recordrtc'

export default {
  components: {
    AudioPlayer
  },
  data() {
    return {
      result: '',
      isDynamic: false,
      isPlay: false,
      historyObj: {
        topic: '',
        answer: ''
      },
      interview_badge_tags_id: '',
      cutscene_speech: [],
      cutscene_speech_index: 0,
      audioSrc: 'https://app.bmbcode.com/storage/qrcode/20240507/D5zsF5jMhIoounzmDzwYp0TfpI88e54o66398b87309aa.mp3',
      isNext: true,
      disabled: true,
      index: 1,
      history_id: [],
      loading: null,
      videoStream: null,

      voiceStatus: false,
      chooseMicDeviceId: '',
      stream: null,
      recorder: null
    }
  },
  watch: {
    index (newVal) {
      if (newVal === this.cutscene_speech.length) {
        this.clickReport()
      }
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    startRecognition() {
      if (this.voiceStatus) {
        this.stopRecord()
      } else {
        this.startRecord()
      }
    },
    // 结束录音
    stopRecord() {
      this.voiceStatus = false
      if (this.recorder !== null) {
        this.recorder.stopRecording(() => {
          this.sendData(this.recorder.getBlob())
          this.recorder = null
          this.stream.getAudioTracks().forEach(track => track.stop());
        })
      }
    },
    // 开始录音
    startRecord() {
      this.voiceStatus = true
      // 请求访问指定设备id的麦克风
      window.navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.chooseMicDeviceId } }).then((stream) => {
        this.stream = stream

        // 创建一个音频录制器
        this.recorder = RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/wav',
          recorderType: RecordRTC.StereoAudioRecorder,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1, // 单声道
          // timeSlice: 5000,
          // ondataavailable: (blob) => this.sendData(blob),
        })
        this.recorder.startRecording()
      }).catch((err) => {
        this.voiceStatus = false
        this.$message.error('当前浏览器不支持麦克风', err)
      })
    },
    // 当有音频数据切片时调用
    async sendData(blob) {
      let formData = new FormData()
      formData.append('file', blob, 'file.wav')

      const res = await getSubtitle(formData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      if (res.data.data !== '') {
        this.result = this.result + res.data.data
      }
    },
    // 获取设备信息
    getMic() {
      const that = this
      // 检查浏览器是否支持媒体设备API，是否支持获取媒体流的方法
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 请求访问用户麦克风权限
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
          // 用户同意，获取一个包含音频的对象

          // 列出所有媒体设备
          navigator.mediaDevices.enumerateDevices().then(function (devices) {
            devices.forEach(function (device) {
              // 选择设备是音频输入设备
              if (device.kind === 'audioinput') {
                // 并且设备ID不是默认的，也不是通信用的
                if (device.deviceId !== 'default' && device.deviceId !== 'communications') {
                  that.chooseMicDeviceId = device.deviceId
                }
              }
            })
          })

          // 停止所有音频轨道
          stream.getTracks().forEach(track => track.stop())
        })
      }
    },
    stopCamera() {
      if (this.videoStream) {
        // 获取视频流中的所有轨道（可能是视频轨道或音频轨道）
        const tracks = this.videoStream.getTracks();
        // 遍历所有轨道并停止它们
        tracks.forEach(track => track.stop());
        this.videoStream = null
      }
    },
    // 生成报告
    clickReport() {
      if (!this.disabled) return

      if (this.index === this.cutscene_speech.length) {
        if (this.loading !== null) {
          this.loading.close()
        }
        this.stopCamera()

        this.$router.push(
          {
            path: 'interviewHistory',
            query: {
              history_id: this.history_id.join()
            }
          }
        )
      } else {
        this.loading = this.$loading({
          lock: true,
          text: '报告生成中，请稍等……',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.2)'
        });
      }
    },
    handleChildEvent() {
      this.isDynamic = !this.isDynamic;
      if (this.isNext) {
        this.nextAnswering()
      }
    },
    async startCamera() {
      this.startAnswering()
      try {
        // video: true 表示只请求视频流
        this.videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        // 将获取到的视频流设置为video元素的源
        this.$refs.videoElement.srcObject = this.videoStream;
      } catch (err) {
        this.videoStream = null
      }
    },
    // 开始答题
    startAnswering() {
      this.isDynamic = !this.isDynamic
      this.isPlay = true
      if (this.cutscene_speech.length - 1 === this.cutscene_speech_index) {
        this.disabled = true
      } else {
        this.disabled = false
      }
      this.historyObj.answer = ''
      if (this.$refs.childRef) {
        this.$refs.childRef.togglePlay()
      }
    },
    // 下一题
    nextAnswering() {
      this.historyObj.topic = this.cutscene_speech[this.cutscene_speech_index].question_topic
      this.audioSrc = this.cutscene_speech[this.cutscene_speech_index].question_video
      this.startAnswering()
      this.isNext = false
    },
    async sendMessage(postData) {
      try {
        const res = await createInterviewGPT(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.history_id.push(res.data.data)
        this.index += 1
        
      } catch (error) {
        this.sendMessage(postData)
      }
    },
    stopRecognition() {
      if (this.isDynamic || this.result === '') return

      this.stopRecord()
      let postData = {
        result: this.result,
        interview_question_id: this.cutscene_speech[this.cutscene_speech_index].interview_question_id,
        interview_badge_tags_id: this.cutscene_speech[this.cutscene_speech_index].interview_badge_tags_id
      }
      this.sendMessage(postData)

      this.historyObj.answer = this.result
      this.result = ''

      this.cutscene_speech_index = this.cutscene_speech_index + 1
      this.audioSrc = ''  // 没看懂

      if (this.cutscene_speech.length === this.cutscene_speech_index) {
        this.isDynamic = true
        this.historyObj.answer = ''
      } else {
        this.isNext = true
        this.startAnswering()
      }
    },
    async init() {
      const res = await getInterviewGPT(this.interview_badge_tags_id)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      this.cutscene_speech = [...res.data.data, {question_topic: '', question_video: 'https://app.bmbcode.com/storage/qrcode/20240507/F69cui7n14qzhIWZuEkKyFnIhN4CMt4N66398bd489f4b.mp3'}]
    }
  },
  mounted () {
    this.init()
    this.getMic()
  },
  created () {
    this.interview_badge_tags_id = this.$route.query.interview_badge_tags_id
  }
}
</script>

<style lang="less" scoped>
.interview-dialogue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .chat-main {
    height: 92%;
    display: flex;
    flex-direction: column;
    .main-picture {
      margin-bottom: 10px;
      overflow: hidden;
      height: 80%;
      position: relative;
      .img {
        width: 100%;
        z-index: 1;
      }
      .deo {
        position: absolute;
        z-index: 2;
        width: 30%;
      }
    }
    .main-content {
      flex: 1;
      padding: 10px;
      border: 1px solid #7eacff;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .content-top {
        height: 90px;
        border-bottom: 1px solid #7a7c7e;
        display: flex;
        flex-direction: column;
        .title-button {
          color: #7a7c7e;
          font-size: 12px;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          .title {
            margin-right: 14px;
          }
        }
        .title-history {
          flex: 1;
          overflow-y: auto;
          padding: 4px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .content-bottom {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 8px;
        .bottom-button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 8px;
          .recognitionlisten {
            height: 40px;
          }
          .recognition {
            height: 40px;
            cursor: pointer;
          }
          .stopRecognition {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>